import { useEffect, useRef } from "react";

export const useImageLoader = (callback) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.onload = callback;
    }
    if (ref.current?.complete) {
      callback();
    }
  }, [callback, ref]);

  return ref;
};
