import "./Services.css";
import { NavWithBrand } from "../views/NavWithBrand";
import { ServicesFooter } from "../views/ServicesFooter";

function Services() {
  return (
    <div id="services-container">
      <NavWithBrand withBrand />
      <ServicesFooter />
    </div>
  );
}

export default Services;
