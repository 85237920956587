import { useCallback, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { mq } from "../utils";

const centerOffsetY = 235;
const separationScale = 0.1;
const distanceScale = 0.25;

const lerpTimeS = 0.75;

const enableInteraction = true;

const BrandContainer = styled.div`
  display: flex;
  //position: absolute;
  //width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 15vh;

  ${mq[3]} {
    margin-top: 15vh;
  }

  ${mq[2]} {
    margin-top: 12vh;
  }

  ${mq[1]} {
    margin-top: 8vh;
  }

  ${mq[0]} {
    margin-top: 0;
  }
`;

const Relative = styled.div`
  position: relative;
`;

function lerp(from, to, percent) {
  percent = Math.min(Math.max(0, percent), 1);
  return from + (to - from) * percent;
}

function flip(n) {
  return 1 - n;
}

function square(n) {
  return n * 2;
}

function easeOut(n) {
  return flip(square(flip(n)));
}

export const InteractiveLogo = () => {
  const shadows = [useRef(), useRef(), useRef(), useRef()];

  const rendering = useRef(true);
  const lastFrame = useRef(Date.now());
  const positions = useRef({
    sX: 0,
    sY: 0,
    dX: 0,
    dY: 0,
    mX: 0,
    mY: 0,
  });

  const frame = useCallback(() => {
    const now = Date.now();
    const tpf = 1 / (now - lastFrame.current);
    lastFrame.current = now;

    // console.log("tpf", tpf);

    const p = positions.current;

    const centerX = window.innerWidth * 0.5;
    const centerY = window.innerHeight * 0.5 - centerOffsetY;

    p.dX = (centerX - p.mX) * distanceScale;
    p.dY = (centerY - p.mY) * distanceScale;

    if (p.sX === 0) {
      p.sX = p.dX;
      p.sY = p.dY;
    } else {
      // p.sX += vectorX * tpf * lerpTimeS;
      // p.sY += vectorY * tpf * lerpTimeS;
      const percent = tpf * (1 / lerpTimeS);
      // console.log({ percent });
      p.sX = lerp(p.sX, easeOut(p.dX), percent);
      p.sY = lerp(p.sY, easeOut(p.dY), percent);
    }

    // console.log(p);

    let curScale = separationScale;
    for (let i = 0; i < shadows.length; i++) {
      const shadow = shadows[i];
      const newX = curScale * p.sX;
      const newY = curScale * p.sY;
      curScale += separationScale;

      // p.sX = lerp(vectorX, newX, percent);
      // p.cY = lerp(vectorY, newY, percent);

      if (!shadow.current) {
        return;
      }

      shadow.current.style.transform = `translate(${newX}px,${newY}px)`;
    }
    if (rendering.current) {
      window.requestAnimationFrame(frame);
    }
  }, []);

  useEffect(() => {
    const handleMove = (e) => {
      // Don't request animation frame more than necessary?
      positions.current.mX = e.clientX;
      positions.current.mY = e.clientY;
    };

    if (enableInteraction) {
      rendering.current = true;
      frame();
      document.addEventListener("mousemove", handleMove);
      return () => {
        rendering.current = false;
        document.removeEventListener("mousemove", handleMove);
      };
    }
  }, []);

  return (
    <BrandContainer>
      <Relative className={"brand-animation"}>
        <h1 className="brand-home">audio.expert</h1>
        <h1 className="brand-shadow-1" aria-hidden ref={shadows[0]}>
          audio.expert
        </h1>
        <h1 className="brand-shadow-2" aria-hidden ref={shadows[1]}>
          audio.expert
        </h1>
        <h1 className="brand-shadow-3" aria-hidden ref={shadows[2]}>
          audio.expert
        </h1>
        <h1 className="brand-shadow-4" aria-hidden ref={shadows[3]}>
          audio.expert
        </h1>
      </Relative>
    </BrandContainer>
  );
};
