import {
  CanvasFooterRenderer,
  ReusableCanvasFooter,
} from "./ReusableCanvasFooter";

const config = {
  devMode: false,
  mp4Url:
    "https://elasticbeanstalk-us-east-2-900042174014.s3.us-east-2.amazonaws.com/video/AudioExpert-ServicesGrid-720.mp4",
  imageUrl:
    "https://elasticbeanstalk-us-east-2-900042174014.s3.us-east-2.amazonaws.com/img/services/tvs.jpg",
  maskImageUrl:
    "https://elasticbeanstalk-us-east-2-900042174014.s3.us-east-2.amazonaws.com/img/services/tvs-mask.png",
  fullBleed: false,
  shouldMaskVideo: true,
  refCellSizeX: 106.67,
  refCellSizeY: 120,
  cellCountX: 6,
  cellCountY: 5,
  trackFps: false,
  canvasW: 2170,
  canvasH: 1723,
  vidCount: 27,
  videoPositions: [
    [3.3, 0, -0.01, 2.3, 903, 516, 0],
    [2.8, -0.24, 0, 2.2, 881, 172, 0],
    [3.3, 0, 0, 1.8, 1754, 372, 90],
    [5, 0.04, -0.01, 2.5, 1302, 675, 90],
    [3.3, -0.03, 0, 2.3, 291, 332, 0],
    [4.8, 0.04, -0.01, 4, 131, 906, 0],
    [2.6, -0.1, 0, 2.9, 1711, 922, 0],
  ],
};

const renderer = new CanvasFooterRenderer(config);

export function ServicesFooter() {
  return <ReusableCanvasFooter renderer={renderer} />;
}
