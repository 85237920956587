/**
 * Inclusive
 */
export const randInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max) + 1;
  return Math.floor(Math.random() * (max - min) + min);
};

const breakpoints = [576, 768, 992, 1200, 1600];

export const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);
