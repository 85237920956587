import './WorkPlayer.css';
import {useNavigate} from "react-router-dom";

function WorkPlayer({item, onLoad}) {
  const navigate = useNavigate();

  if (!item) {
    return;
  }

  return (
    <div className="work-player" id="work-player">
      <a href="#" id="work-player-close" onClick={() => navigate(-1)}>
        <div>&times;</div>
      </a>
      <div className="work-player-content">
        <div className="work-vimeo-player" id="work-player-host">
          <iframe className="work-vimeo-frame"
                  src={`https://player.vimeo.com/video/${item.videoId}?autoplay=1&loop=0&autopause=0&muted=0&controls=1`}
                  width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen
                  onLoad={() => onLoad()}/>
        </div>
        <div className="work-player-info">
          <h1 id="work-player-info-title">{item.title}</h1>
          <h2 id="work-player-info-client">// {item.client}</h2>
          <div id="work-player-info-description">{item.description}</div>
        </div>
      </div>
      <div className="work-player-loading">
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
        Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z Z
      </div>
    </div>
  );
}

export default WorkPlayer;