import styled from "@emotion/styled";
import { useEffect, useMemo, useRef } from "react";
import { PositionHelper } from "./PositionHelper";
import { useImageLoader } from "../hooks/useImageLoader";
import { mq, randInt } from "../utils";

// const videoWidth = 1920;
// const videoHeight = 1080;
const videoWidth = 1280;
const videoHeight = 720;

const HiddenVideo = styled.video`
  position: fixed;
  bottom: 0;
  left: 0;
  width: ${videoWidth}px;
  height: ${videoHeight}px;
  z-index: -1000;
  opacity: 0;
  pointer-events: none;
`;

const HiddenVideoMask = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: ${videoWidth + 10}px;
  height: ${videoHeight + 10}px;
  z-index: -999;
  background: var(--bg-color);
`;

const HiddenImage = styled.img`
  position: fixed;
  visibility: hidden;
  //top: -10000px;
  //left: -10000px;
`;

const StyledCanvas = styled.canvas`
  //background: #410000;
  //border: 1px #f00 solid;
  min-width: 100%;
  height: 100%;
  box-sizing: border-box;

  // ${mq[2]} {
  //   width: 150%;
  //   margin-left: -37.5%;
  // }
  //
  // ${mq[1]} {
  //   width: 200%;
  //   margin-left: -50%;
  // }
  //
  // ${mq[0]} {
  //   width: 300%;
  //   margin-left: -75%;
  // }
`;

const HiddenCanvas = styled.canvas`
  position: absolute;
  visibility: hidden;
`;

const FpsCounter = styled.div`
  position: fixed;
  background: #000;
  padding: 10px;
  color: #fff;
  top: 0;
  left: 50%;
`;

// const thing = () => {
//   var canvas = document.getElementById("canvas");
//   var ctx = canvas.getContext("2d");
//
//   video.onplay = function () {
//     canvas.width = video.videoWidth;
//     canvas.height = video.videoHeight;
//     draw();
//   };
//
//   function draw() {
//     if (video.paused || video.ended) return false;
//     ctx.drawImage(video, 0, 0);
//     setTimeout(draw, 20);
//   }
// };

// a (m11)
// Horizontal scaling. A value of 1 results in no scaling.
//
// b (m12)
// Vertical skewing.
//
// c (m21)
// Horizontal skewing.
//
// d (m22)
// Vertical scaling. A value of 1 results in no scaling.
//
// e (dx)
// Horizontal translation (moving).
//
// f (dy)
// Vertical translation (moving).

// TODO: Remove this once positions are in place to improve perf

// state

// config

export class CanvasFooterRenderer {
  config;
  state;

  constructor(config) {
    this.config = config;
    this.drawBound = this.draw.bind(this);

    this.state = {
      ctx: null,
      imageCtx: null,
      video: null,
      bgImage: null,
      bgImageW: 1,
      bgImageH: 1,
      maskImage: null,
      cellSizeX: config.refCellSizeX,
      cellSizeY: config.refCellSizeY,
      cellScaleX: 1,
      cellScaleY: 1,
      videoOffset: randInt(0, config.vidCount - 1),
      vidPositionCount: config.videoPositions.length,
      aspectRatio: Math.round((config.canvasW / config.canvasH) * 100) * 0.01,
      lastTime: Date.now(),
      frameCount: 0,
      lastFrameCount: 0,
      lastDrawTime: 0,
    };
  }

  draw() {
    const { config, state } = this;
    const start = Date.now();

    const {
      ctx,
      imageCtx,
      video,
      bgImage,
      bgImageW,
      bgImageH,
      maskImage,
      cellSizeX,
      cellSizeY,
      cellScaleX,
      cellScaleY,
      videoOffset,
      vidPositionCount,
    } = state;

    const {
      shouldMaskVideo,
      cellCountX,
      trackFps,
      canvasW,
      canvasH,
      videoPositions,
      vidCount,
    } = config;

    // First draw & mask the image
    imageCtx.globalCompositeOperation = "source-over";
    imageCtx.drawImage(
      bgImage,
      0,
      0,
      bgImageW,
      bgImageH,
      0,
      0,
      canvasW,
      canvasH,
    );

    imageCtx.globalCompositeOperation = "destination-in";
    imageCtx.drawImage(
      maskImage,
      0,
      0,
      bgImageW,
      bgImageH,
      0,
      0,
      canvasW,
      canvasH,
    );

    // Now we'll draw the videos and mask those too
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.globalCompositeOperation = "source-over";

    // Clear everything to redraw
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    for (let idx = 0; idx < vidPositionCount; idx++) {
      // const i = (idx + videoOffset) % videoPositions.length;
      const i = idx;

      const srcI = (idx + videoOffset) % vidCount;
      const sx = (srcI % cellCountX) * cellSizeX;
      const sy = Math.floor(srcI / cellCountX) * cellSizeY;
      // ctx.translate(videoPositions[i][4], videoPositions[i][5]);
      //
      // if (
      //   (videoPositions[i][1] || videoPositions[i][2]) &&
      //   videoPositions[i][6]
      // ) {
      //   throw new Error("Don't use skew and rotation together on index " + i);
      // }

      ctx.setTransform(
        1, // horizontal scaling stay constant
        videoPositions[i][1],
        videoPositions[i][2],
        1, // vertical scaling stay constant
        videoPositions[i][4],
        videoPositions[i][5],
      );

      ctx.rotate((videoPositions[i][6] * Math.PI) / 180);

      if (video) {
        // ctx.drawImage(video, 0, 0);
        ctx.drawImage(
          video,
          sx,
          sy,
          cellSizeX,
          cellSizeY,
          0,
          0,
          cellSizeX * videoPositions[i][0] * cellScaleX,
          cellSizeY * videoPositions[i][3] * cellScaleY,
        );
      } else {
        ctx.beginPath();
        ctx.rect(
          0,
          0,
          cellSizeX * videoPositions[i][0] * cellScaleX,
          cellSizeY * videoPositions[i][3] * cellScaleY,
        );
        ctx.fillStyle = "black";
        ctx.fill();
      }
    }

    ctx.setTransform(1, 0, 0, 1, 0, 0);

    // This is how we mask the videos
    if (shouldMaskVideo) {
      ctx.globalCompositeOperation = "destination-out";
      ctx.drawImage(
        maskImage,
        0,
        0,
        bgImageW,
        bgImageH,
        0,
        0,
        canvasW,
        canvasH,
      );
    }

    // Now draw the TVs over it all
    // Correct masking:
    ctx.globalCompositeOperation = shouldMaskVideo
      ? "source-over"
      : "destination-over";

    ctx.drawImage(imageCtx.canvas, 0, 0);

    window.requestAnimationFrame(this.drawBound);

    // FPS Tracking!
    state.frameCount++;

    if (trackFps) {
      if (Date.now() > state.lastTime + 1000) {
        state.lastTime = Date.now();
        state.lastFrameCount = state.frameCount;
        state.frameCount = 0;
      }

      state.lastDrawTime = Date.now() - start;

      document.getElementById(
        "fps",
      ).innerText = `FPS: ${state.lastFrameCount}, Last Draw Time: ${state.lastDrawTime}`;
    }
  }

  handleLoop() {
    this.state.videoOffset++;
  }
}

export function ReusableCanvasFooter({ renderer }) {
  const videoRef = useRef();
  const canvasRef = useRef();
  const hiddenCanvasRef = useRef();
  const didLoopRef = useRef(false);

  const { state, config } = renderer;

  const checkDraw = () => {
    console.log("checkdraw", state.bgImage, state.maskImage);
    if (state.bgImage && state.maskImage) {
      renderer.draw();
    }
  };
  //   const ctx = useMemo(() => canvasRef.current.getContext("2d"), [canvasRef]);

  useEffect(() => {
    state.ctx = canvasRef.current.getContext("2d");
    state.imageCtx = hiddenCanvasRef.current.getContext("2d");

    // TODO: timeupdate to see if it looped?
    // addEventListener("timeupdate"
    // video.currentTime

    // console.log("effect?");
    didLoopRef.current = true;

    const timeUpdateHandler = () => {
      if (!state.video) {
        return;
      }
      // console.log("time", video.currentTime);
      if (!didLoopRef.current && state.video.currentTime < 1) {
        console.log("Video looped!");
        didLoopRef.current = true;
        // do something
        renderer.handleLoop();
      } else if (didLoopRef.current && state.video.currentTime > 1) {
        didLoopRef.current = false;
      }
    };

    videoRef.current.addEventListener("timeupdate", timeUpdateHandler);

    videoRef.current.onplay = function () {
      state.video = videoRef.current;
      state.cellSizeX = state.video.videoWidth / config.cellCountX;
      state.cellSizeY = state.video.videoHeight / config.cellCountY;

      state.cellScaleX = config.refCellSizeX / state.cellSizeX;
      state.cellScaleY = config.refCellSizeY / state.cellSizeY;

      checkDraw();

      return () => {
        videoRef.current.removeEventListener(timeUpdateHandler);
      };
    };
  }, []);

  const bgImageRef = useImageLoader(() => {
    state.bgImage = bgImageRef.current;
    state.bgImageW = state.bgImage.width;
    state.bgImageH = state.bgImage.height;
    checkDraw();
  });
  const maskImageRef = useImageLoader(() => {
    state.maskImage = maskImageRef.current;
    checkDraw();
  });

  const CanvasWrapper = useMemo(
    () => styled.div`
      position: fixed;
      bottom: -6px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      aspect-ratio: ${state.aspectRatio};
      ${config.fullBleed
        ? `
      height: auto; 
      min-width: max(100vw, ${config.canvasW}px);
      min-height: 60%;
      bottom: auto;
      top: 40%
      `
        : `
      width: auto; 
      height: 100vh;
      ${mq[4]} {
        height: 70vh;
      }
      
      ${mq[2]} {
        height: 50vh;
      }
      `}
    `,
    [],
  );

  return (
    <CanvasWrapper>
      {config.devMode && (
        <PositionHelper
          initialValue={config.videoPositions[config.videoPositions.length - 1]}
          onChange={(newValue, shouldMask) => {
            config.videoPositions[config.videoPositions.length - 1] = newValue;
            config.shouldMaskVideo = shouldMask;
          }}
        />
      )}
      {config.trackFps && <FpsCounter id={"fps"} />}
      <HiddenVideo
        ref={videoRef}
        id="video"
        src={config.mp4Url}
        controls={false}
        autoPlay
        muted
        playsInline
        loop
      />
      {/*<HiddenVideoMask />*/}
      <HiddenImage
        src={config.imageUrl}
        alt={"Stack of TVs"}
        ref={bgImageRef}
      />
      <HiddenImage src={config.maskImageUrl} ref={maskImageRef} />
      <StyledCanvas
        ref={canvasRef}
        width={config.canvasW}
        height={config.canvasH}
      />
      <HiddenCanvas
        ref={hiddenCanvasRef}
        width={config.canvasW}
        height={config.canvasH}
      />
    </CanvasWrapper>
  );
}
