import * as React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const useRouter = false;

function NavLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  if (!useRouter) {
    return (
      <a className={match ? "active" : ""} href={to} {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link className={match ? "active" : ""} to={to} {...props}>
      {children}
    </Link>
  );
}

export default NavLink;
