const stripPattern = /[^a-zA-Z0-9- ]/gi;
const capitalPattern = /([a-z])([A-Z])/g;
const spacePattern = /\s+/g;
const hyphenPattern = /-+/g;

function makeSlug(value) {
  return value
    .replaceAll(stripPattern, "")
    .replaceAll(capitalPattern, "$1-$2")
    .replaceAll(spacePattern, "-")
    .replaceAll(hyphenPattern, "-")
    .toLowerCase();
}

/*
New work items here:
Gif format should be "ProjectName.gif" if it's super specific, but if it's more generic, do something like this:
ClientName-ProjectName.gif
Ignore the videoapi URLs, those are just pasted in.

908926758
EUFA // Paramount+

908925991
Y3000AI // Coca-Cola

908925932
Hydration // CVS

908925856
Halloween // Walmart Discovered

908925351
Slivingland // Paris Hilton

908924891
Best of Romance // Audible

908924169
Anthony Anderson // PGA

908924079
Launch // Walmart Discovered

908924033
Inner Goddess // Vitafusion

908923882
Kids // Therabreath

908923824
Roblox Sol // Sol De Janeiro

908923381
Zach Noe Towers // After Hours

908922898
2023 EOY // Netflix is a Joke

908922251
Know Your Max // Moon Rabbit

908922126
Lamborghini // Roblox

908922081
New Wave // Julio Palacio

908922008
Winterfest // Walmart Discovered

879397553
Everything // CDC




*/

const WorkItems = [
  {
    image: "Paramount-EUFA.gif",
    title: "EUFA",
    client: "Paramount+",
    videoId: "908926758",
  },
  {
    image: "CocaCola-Y3000AI.gif",
    title: "Y3000AI",
    client: "Coca-Cola",
    videoId: "908925991",
  },
  {
    image: "CVS-Hydration.gif",
    title: "Hydration",
    client: "CVS",
    videoId: "908925932",
  },
  {
    image: "WalmartHalloween.gif",
    title: "Halloween",
    client: "Walmart Discovered",
    videoId: "908925856",
  },
  {
    image: "Slivingland.gif",
    title: "Slivingland",
    client: "Paris Hilton",
    videoId: "908925351",
  },
  {
    image: "Audible-BestofRomance.gif",
    title: "Best of Romance",
    client: "Audible",
    videoId: "908924891",
  },
  {
    image: "PGA-AnthonyAnderson.gif",
    title: "Anthony Anderson",
    client: "PGA",
    videoId: "908924169",
  },
  {
    image: "Walmart-Launch.gif",
    title: "Launch",
    client: "Walmart Discovered",
    videoId: "908924079",
  },
  // {
  //   image: "Vitafusion-InnerGoddess.gif",
  //   title: "Inner Goddess",
  //   client: "Vitafusion",
  //   videoId: "908924033",
  // },
  {
    image: "Therabreath-Kids.gif",
    title: "Kids",
    client: "Therabreath",
    videoId: "908923882",
  },
  {
    image: "SolDeJaneiro-RobloxSol.gif",
    title: "Roblox Sol",
    client: "Sol De Janeiro",
    videoId: "908923824",
  },
  {
    image: "AfterHours-ZachNoeTowers.gif",
    title: "Zach Noe Towers",
    client: "After Hours",
    videoId: "908923381",
  },
  {
    image: "Netflix2023EOY.gif",
    title: "2023 EOY",
    client: "Netflix is a Joke",
    videoId: "908922898",
  },
  // {
  //   image: "MoonRabbit-KnowYourMax.gif",
  //   title: "Know Your Max",
  //   client: "Moon Rabbit",
  //   videoId: "908922251",
  // },
  {
    image: "Roblox-Lamborghini.gif",
    title: "Lamborghini",
    client: "Roblox",
    videoId: "908922126",
  },
  {
    image: "JulioPalacio-NewWave.gif",
    title: "New Wave",
    client: "Julio Palacio",
    videoId: "908922081",
  },
  {
    image: "Walmart-Winterfest.gif",
    title: "Winterfest",
    client: "Walmart Discovered",
    videoId: "908922008",
  },
  {
    image: "CDC-Everything.gif",
    title: "Everything",
    client: "CDC",
    videoId: "879397553",
  },
  {
    image: "KarmasWorld__Netflix-low.gif",
    title: "Karma’s World",
    client: "Netflix",
    description: "ADR & Song Recordist for Chris (Ludacris) Bridges\nEP 1-4",
    videoId: "662752011",
  },
  {
    image: "NottheScienceType__3M-low.gif",
    title: "Not the Science Type",
    client: "3M",
    videoId: "571838489",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "coke-nft.gif",
    title: "Sonic NFT",
    client: "Coca-Cola",
    description: "Coca-Cola’s First NFT\nEdit, Mix & Sound Design by Zach Hahn",
    videoId: "662752173",
  },
  {
    image: "BeautyStandards__BBC-low.gif",
    title: "Beauty Standards Series",
    client: "BBC",
    description:
      "Edit, Mix, Music Supervision, Original Music & Sound Design by Zach Hahn\nEP 1-3",
    videoId: "662751629",
  },
  {
    image: "Caleb__UPS-low.gif",
    title: "Caleb",
    client: "UPS",
    description: "Edit, Mix & Sound Design by Zach Hahn\nDP: Misha Mazor",
    videoId: "662751911",
  },
  {
    image: "MustardBeer__Frenchs-low.gif",
    title: "Mustard Beer",
    client: "French's",
    videoId: "516891484",
    description: "Edit, Mix, & Sound Design by Zach Hahn",
  },
  {
    image: "Boo2__TBS-low.gif",
    title: "Boo 2",
    client: "TBS",
    videoId: "401689021",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "HomefortheHolidays__ChuggingtonUSA-low.gif",
    title: "Chuggington Season 6",
    client: "Herschend Ent.",
    videoId: "501503347",
    description: "ADR, Dubbing, Voiceover Recording by Zach Hahn",
  },
  {
    image: "Claw__ShellRewards-low.gif",
    title: "Claw",
    client: "Shell Rewards",
    videoId: "401689119",
    description: "Music Search, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "Pranks__Victor&Valentino-low.gif",
    title: "Pranks",
    client: "Victor & Valentino",
    videoId: "402056289",
    description: "Versioning, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "TheBoss__ESPN-low.gif",
    title: "The Boss",
    client: "ESPN",
    videoId: "401688974",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "DeathRowStories__HLN-low.gif",
    title: "Death Row Stories",
    client: "HLN",
    videoId: "402236933",
    description: "Record, Edit, Mix & Versioning by Zach Hahn",
  },
  {
    image: "DamnGoodOil__QuakerState-low.gif",
    title: "Damn Good Oil",
    client: "Quaker State",
    videoId: "401689060",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "ToysRus__TBS-low.gif",
    title: "Toys R Us",
    client: "TBS",
    videoId: "401688892",
    description: "Music Embellishment, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "FreddyAdu__Bluewire-low.gif",
    title: "Freddy Adu",
    client: "Bluewire",
    videoId: "501506902",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "TheCrossing__DirectedbyJulioPalacio-low.gif",
    title: "The Crossing (2020)",
    client: "Dir. by Julio Palacio",
    videoId: "516894270",
    description:
      "Edit, Mix & Sound Design of the film and trailers by Zach Hahn",
  },
  {
    image: "TheUnityCollection__Coca-Cola-low.gif",
    title: "Hilltop",
    client: "Coca-Cola",
    videoId: "571852885",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "Pin__ShellRewards-low.gif",
    title: "Pin",
    client: "Shell Rewards",
    videoId: "402220102",
    description: "Music Search, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "DeepPondering__Glad-low.gif",
    title: "Deep Pondering",
    client: "GLAD",
    videoId: "402250122",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "RiFFRAFF__InstantX-Mas-low.gif",
    title: "RiFF RAFF",
    client: "Instant X-Mas",
    videoId: "402283153",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "holiday-song.gif",
    title: '"Get Dressed Ye"',
    client: "Tommy John",
    description: "Original Music Radio Campaign produced by Zach Hahn",
    videoId: "662788153",
  },
  {
    image: "StatueofLiberty__Disney-low.gif",
    title: "Statue of Liberty",
    client: "Disney",
    description: "Edit, Mix & Sound Design by Zach Hahn",
    videoId: "662753673",
  },
  {
    image: "amazon2.gif",
    title: "Hungry Fam",
    client: "Amazon",
    description: "Edit, Mix & Sound Design by Zach Hahn",
    videoId: "662753567",
  },
  {
    image: "amazon1.gif",
    title: "Home Makeover",
    client: "Amazon",
    description: "Edit, Mix & Sound Design by Zach Hahn",
    videoId: "662752446",
  },
  {
    image: "fanta.gif",
    title: "Flavorween",
    client: "Fanta",
    description:
      "Edit, Mix, Original Music, Voiceover Casting & Sound Design by Zach Hahn",
    videoId: "662752376",
  },
  {
    image: "AhmanGreen__NFL-low.gif",
    title: "Ahman Green",
    client: "NFL",
    description: "Edit, Mix & Sound Design by Zach Hahn",
    videoId: "662752197",
  },
  {
    image: "Mobile__TriNet-low.gif",
    title: "Mobile",
    client: "TriNet",
    description: "Voiceover Casting & Recording by Zach Hahn",
    videoId: "662752157",
  },
  {
    image: "jj.gif",
    title: "Laurie Hernandez",
    client: "Jimmy John’s",
    description: "Edit, Mix & Sound Design by Zach Hahn",
    videoId: "662752103",
  },
  {
    image: "Dev__MermaidHigh-low.gif",
    title: "Dev",
    client: "Mermaid High",
    description: "ADR & Dialogue recordist for “Dev”\nEP 2-5",
    videoId: "662751994",
  },
  {
    image: "SipnScan__Speedway-low.gif",
    title: "Sip n Scan",
    client: "Speedway",
    videoId: "501493937",
    description:
      "Voiceover Casting, Recording, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "TheFutureofCommerce__SEEK-low.gif",
    title: "The Future of Commerce",
    client: "SEEK",
    videoId: "501507102",
    description: "Voiceover Casting & Recording by Zach Hahn",
  },
  {
    image: "YerbaMate__HonestTea-low.gif",
    title: "Yerba Mate",
    client: "Honest Tea",
    videoId: "571839377",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "SonicBranding__DesignCheeeese-low.gif",
    title: "Sonic Branding",
    client: "Design Cheese",
    videoId: "501500923",
    description: "Original Music & Sound Design by Zach Hahn",
  },
  {
    image: "CoachBeard__MarketStreet-low.gif",
    title: "Coach Beard",
    client: "Market Street",
    videoId: "571839367",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "CLUEDO__Netflix-low.gif",
    title: "CLUEDO",
    client: "Netflix",
    videoId: "402232170",
    description:
      "Music Search, Voice Casting, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "GIVE__Coca-Cola-low.gif",
    title: "GIVE",
    client: "Coca-Cola",
    videoId: "401688989",
    description:
      "Music Search, Voice Casting, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "WannaBite__SlimJim-low.gif",
    title: "Wanna Bite",
    client: "Slim Jim",
    videoId: "401689132",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "Toyland__SparklePaperTowels-low.gif",
    title: "Toyland",
    client: "Sparkle Paper Towels",
    videoId: "401689039",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "ToyAnn__UPS-low.gif",
    title: "ToyAnn",
    client: "UPS",
    videoId: "501501329",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "Touchdown__ShellRewards-low.gif",
    title: "Touchdown",
    client: "Shell Rewards",
    videoId: "402218503",
    description: "Music Search, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "Crashletes__Nickelodeon-low.gif",
    title: "Crashletes",
    client: "Nickelodeon",
    videoId: "401689145",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "CarlyPatterson__MarketStreet-low.gif",
    title: "Olympian Carly Patterson",
    client: "Market Street",
    videoId: "571841731",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "Liars__OneAlaska-low.gif",
    title: "Liars",
    client: "One Alaska",
    videoId: "501500739",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "ASICS__Footlocker-low.gif",
    title: "ASICS",
    client: "Footlocker",
    videoId: "401688955",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "Heroes&Survivors__TheWeatherChannel-low.gif",
    title: "Heroes & Survivors",
    client: "The Weather Channel",
    videoId: "402267208",
    description:
      "Audio Post Production Supervisor, Edit, Mix & Sound Design for 2 Seasons",
  },
  {
    image: "Daedalus__Logitech-low.gif",
    title: "Daedalus",
    client: "Logitech",
    videoId: "402199582",
    description:
      "Music Search, Music Embellishment, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "MOLLY__IndieFilm-low.gif",
    title: "Molly",
    client: "Phyllis + Shua Jackson",
    videoId: "402227076",
    description: "Noise Reduction, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "ThePhog__ESPN-low.gif",
    title: "The Phog",
    client: "ESPN",
    videoId: "402225293",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "StevenUniverse__SDCC2019-low.gif",
    title: "Steven Universe",
    client: "SDCC 2019",
    videoId: "402247207",
    description: "Edit & Mix by Zach Hahn",
  },
  {
    image: "SharkWeek__Discovery-low.gif",
    title: "Shark Week",
    client: "Discovery",
    videoId: "402174385",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "FullCourtPranksOpen__TruTV-low.gif",
    title: "Full Court Pranks Open",
    client: "TruTV",
    videoId: "401688918",
    description: "Music Search, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "SOS__TheWeatherChannel-low.gif",
    title: "SOS",
    client: "The Weather Channel",
    videoId: "402263720",
    description:
      "Audio Post Production Supervisor, Edit, Mix & Sound Design for Season 1",
  },
  {
    image: "Speedway__Coca-Cola-low.gif",
    title: "Speedway",
    client: "Coca-Cola",
    videoId: "401688900",
    description: "Music Search, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "Cranberry__TropicalSmoothieCafe-low.gif",
    title: "Cranberry",
    client: "Tropical Smoothie Cafe",
    videoId: "402217125",
    description: "Record, Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "TheBeginning__KieferSutherland-low.gif",
    title: "The Beginning",
    client: "Kiefer Sutherland",
    videoId: "401689160",
    description: "Edit & Mix by Zach Hahn",
  },
  {
    image: "ValkyrieTrailer__EVE-low.gif",
    title: "Valkyrie Trailer",
    client: "CCP Games",
    videoId: "402269104",
    description: "Edit, Mix & Sound Design by Zach Hahn",
  },
  {
    image: "WeatherGoneViral__TheWeatherChannel-low.gif",
    title: "Weather Gone Viral",
    client: "The Weather Channel",
    videoId: "402257950",
    description:
      "Audio Post Production Supervisor, Edit, Mix & Sound Design for 4 Seasons",
  },
  {
    image: "77EFlameArrestor__CDCGroth-low.gif",
    title: "77E Flame Arrestor",
    client: "Groth Corp.",
    videoId: "571858092",
    description: "Music Licensing, Edit, Mix & Sound Design by Zach Hahn",
  },
].map((item) => {
  /*
              $workItem['slug'] = str_replace(
                  ' ',
                  '-',
                  str_replace('', '', $workItem['client']) . '-' .
                  $workItem['title']
              );
              $workItemsById[$workItem['videoId']] = $workItem;
              $workItems[$index] = $workItem;
          */

  return {
    slug: `${makeSlug(item.client)}-${makeSlug(item.title)}`,
    ...item,
  };
});

const WorkItemsById = new Map(
  WorkItems.map((item) => {
    return [item.videoId, item];
  }),
);

const WorkItemsBySlug = new Map(
  WorkItems.map((item) => {
    return [item.slug, item];
  }),
);

export { WorkItems, WorkItemsById, WorkItemsBySlug };
