import "./Contact.css";
import { NavWithBrand } from "../views/NavWithBrand";
import Obfuscate from "react-obfuscate";

function Contact() {
  const contactDetail = (
    <>
      <div className={"contact-detail-1"} aria-hidden>
        <Obfuscate email="zach@audio.expert" />
        <Obfuscate tel="765-418-1358" />
      </div>
    </>
  );

  return (
    <div id="contact-container">
      <NavWithBrand withBrand />
      <div id={"contact-content"}>
        <div id={"contact-image"}>
          <img src={"/img/contact/phone.jpg"} alt={"Red phone"} />
        </div>
        <div id={"contact-details"}>
          {contactDetail}
          {contactDetail}
          {contactDetail}
          {contactDetail}
          {contactDetail}
          {contactDetail}
          {contactDetail}
          {contactDetail}
          {contactDetail}
          {contactDetail}
        </div>
      </div>
    </div>
  );
}

export default Contact;
