import "./Work.css";
import { WorkItems, WorkItemsBySlug } from "../workItems";
import { Link, useParams } from "react-router-dom";
import WorkPlayer from "../views/WorkPlayer";
import { useEffect, useState } from "react";
import { NavWithBrand } from "../views/NavWithBrand";

function Work() {
  const params = useParams();
  const [selectedItem, setSelectedItem] = useState();
  const [playerLoaded, setPlayerLoaded] = useState();

  useEffect(() => {
    setSelectedItem(WorkItemsBySlug.get(params.slug));
    setPlayerLoaded(false);
  }, [params.slug]);

  const workItems = WorkItems.map((item) => (
    <Link to={`/work/${item.slug}`} className="work-item" key={item.videoId}>
      <img src={`/img/work/gifs/${item.image}`} alt={item.title} />
      <div className="work-label">
        {item.title}
        <div>{item.client}</div>
      </div>
      <div className="work-play">&nbsp;&#9658;</div>
      <div className="work-item-hover">
        <div className="work-item-hover-text">
          <div className="work-label-hover">
            <div className={"work-label-title"}>{item.title}</div>
            <div className={"work-label-client"}>{item.client}</div>
          </div>
          <div className={"work-item-watch"}>WATCH ▶︎▶︎▶︎</div>
        </div>
      </div>
    </Link>
  ));

  const classNames = ["work-container"];
  if (selectedItem) {
    classNames.push("player-open");
    classNames.push(playerLoaded ? "player-loaded" : "player-loading");
  }

  return (
    <div className={classNames.join(" ")} id="work-container">
      <NavWithBrand withBrand />
      <div id="work-grid">
        <div className="work-items">{workItems}</div>
      </div>
      <WorkPlayer item={selectedItem} onLoad={() => setPlayerLoaded(true)} />
    </div>
  );
}

export default Work;
