import "./About.css";
import { NavWithBrand } from "../views/NavWithBrand";

function About() {
  return (
    <div id="about-container">
      <NavWithBrand withBrand />
      <div className={"about-container"}>
        <div className="about-background" />
        <div className="about-info">
          Founded amidst the pandemic's tumultuous waves, audio.expert is the
          brainchild of Zach Hahn, a Sonic Wizard with one resounding goal...
          Forge a razor-sharp Swiss Army knife team of remote sound designers
          hell-bent on sculpting soundscapes that not only captivate but
          dominate.
        </div>
        <div className="about-decoration-cat">
          <div className="about-decoration-inner">
            <div>
              <div className="about-cat-constrain">
                <div className="about-cat-image">
                  <img src="/img/about/cat-web.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-decoration-bubble">
          <div className="about-decoration-inner">
            <div>
              <div className="about-bubble-constrain">
                <div className="about-bubble-image">
                  <img src="/img/about/bubble-web.gif" />
                </div>
                <div className="about-bubble-text">
                  <div>I've been stuck in this website for 36 years.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
