import styled from "@emotion/styled";
import { InteractiveLogo } from "../views/InteractiveLogo";
import HomeCanvasFooter from "../views/HomeCanvasFooter";
import "./Home.css";
import { NavWithBrand } from "../views/NavWithBrand";

const HomeFixedSpace = styled.div`
  position: fixed;
  background: var(--bg-color);
  height: 100%;
  width: 100%;
`;

const HomeFlex = styled.div`
  //display: flex;
  //flex-direction: column;
  //height: 100%;
  //justify-content: space-between;
  //align-items: center;
`;

function Home() {
  return (
    <HomeFixedSpace>
      <HomeFlex>
        <NavWithBrand />
        <InteractiveLogo />
        <HomeCanvasFooter />
      </HomeFlex>
    </HomeFixedSpace>
  );
}

export default Home;
