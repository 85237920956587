import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  gap: 10px;
`;

const NumberInput = styled.input`
  width: 80px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const labels = [
  "ScaleX",
  "SkewY",
  "SkewX",
  "ScaleY",
  "TranslateX",
  "TranslateY",
  "Rotate",
];

const stepSizes = [0.1, 0.01, 0.01, 0.1, 1, 1, 0.5];

export const PositionHelper = ({ initialValue, onChange }) => {
  const valueRef = useRef(initialValue);
  const [value, setValue] = useState(initialValue);
  const [stepScale, setStepScale] = useState(1.0);
  const [shouldMask, setShouldMask] = useState(false);

  useEffect(() => {
    const keydownHandler = (event) => {
      if (event.key === "Shift") {
        setStepScale(10);
      }
    };

    const keyupHandler = (event) => {
      if (event.key === "Shift") {
        setStepScale(1);
      }
    };

    document.addEventListener("keydown", keydownHandler);
    document.addEventListener("keyup", keyupHandler);

    return () => {
      document.removeEventListener("keydown", keydownHandler);
      document.removeEventListener("keyup", keyupHandler);
    };
  });
  const handleChange = (event) => {
    const floatValue = parseFloat(event.target.value);
    if (isNaN(floatValue)) {
      return;
    }

    valueRef.current[event.target.dataset.index] = floatValue;
    onChange(valueRef.current, shouldMask);
    setValue([...valueRef.current]);
  };

  // a (m11)
  // Horizontal scaling. A value of 1 results in no scaling.
  //
  // b (m12)
  // Vertical skewing.
  //
  // c (m21)
  // Horizontal skewing.
  //
  // d (m22)
  // Vertical scaling. A value of 1 results in no scaling.
  //
  // e (dx)
  // Horizontal translation (moving).
  //
  // f (dy)
  // Vertical translation (moving).

  return (
    <Container>
      {labels.map((label, index) => (
        <Row key={index}>
          <label>{label}</label>
          <NumberInput
            type="number"
            step={stepSizes[index] * stepScale}
            onChange={handleChange}
            data-index={index}
            defaultValue={value[index]}
          />
        </Row>
      ))}
      <div>
        <label>
          <input
            type="checkbox"
            defaultChecked={shouldMask}
            onChange={(event) => {
              setShouldMask(event.target.checked);
              onChange(valueRef.current, event.target.checked);
            }}
          />
          Mask
        </label>
      </div>
      <div>{JSON.stringify(value)}</div>
    </Container>
  );
};
