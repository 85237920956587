import Nav from "./Nav";
import "./NavWithBrand.css";
import { Link } from "react-router-dom";

export const NavWithBrand = ({ withBrand }) => {
  return (
    <div id={"nav-with-brand"}>
      <div id={"nav-brand"}>
        {withBrand && <Link to={"/"}>audio.expert</Link>}
      </div>
      <Nav />
    </div>
  );
};
