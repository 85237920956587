import {
  CanvasFooterRenderer,
  ReusableCanvasFooter,
} from "./ReusableCanvasFooter";

const config = {
  devMode: false,
  mp4Url:
    "https://elasticbeanstalk-us-east-2-900042174014.s3.us-east-2.amazonaws.com/video/AudioExpert-HomeGrid-720.mp4",
  imageUrl:
    "https://elasticbeanstalk-us-east-2-900042174014.s3.us-east-2.amazonaws.com/img/home/tvs.jpg",
  maskImageUrl:
    "https://elasticbeanstalk-us-east-2-900042174014.s3.us-east-2.amazonaws.com/img/home/tvs-mask.png",
  fullBleed: true,
  shouldMaskVideo: true,
  refCellSizeX: 106.67,
  refCellSizeY: 120,
  cellCountX: 6,
  cellCountY: 5,
  trackFps: false,
  canvasW: 1800,
  canvasH: 473,
  vidCount: 29,
  videoPositions: [
    [0.4, 0, 0, 0.4, 1626, 397, 38.5],
    [0.9, 0, 0, 0.7, 1474, 342, -101],
    [1, 0, 0, 1, 28, 377, 40],
    [0.9, 0, 0, 0.7, 447, 242, -65],
    [1.5, 0, 0, 0.9, 561, 81, -10],
    [0.6, 0.5, 0.4, 0.7, 651, 68, 0],
    [1.1, 0, 0, 0.8, 1476, 165, 114.5],
    [1.2, 0, 0, 0.7, 608, 428, -15],
    [0.4, 0, 0, 1.3, -11, 452, -92.5],
    [0.7, -0.42, 0.2, 0.9, -32, 182, 0],
    [1.3, 0, 0, 1.3, 1466, 493, -100],
    [1.4, 0, 0, 0.9, 1123, 141, -18.5],
    [1, 0, 0, 0.7, 1008, 147, 10.5],
    [1, 1.04, -0.51, 0.8, 1230, 186, 0],
    [1.2, 0, 0, 1, 828, 54, -5.5],
    [1.3, 0, 0, 1.1, 825, 264, -68.5],
    [1.4, 0.8, -0.4, 1, 588, 168, 0],
    [1.2, -1.3, 1.58, 0.75, 180, 310, 0],
    [1.8, 0, 0, 1.3, 391, 413, -31.5],
    [1.7, 0.2, -0.2, 1, 911, 280, 0],
    [1, 0.92, -0.17, 0.9, 1636, 236, 0],
  ],
};

const renderer = new CanvasFooterRenderer(config);

export default function HomeCanvasFooter() {
  return <ReusableCanvasFooter renderer={renderer} />;
}
